<template>
	<div class="page mygroup_page">
		<div class="w1240" v-if="typeindex==0">
			<div class="block30"></div>
			<div class="mygroup_typebox">
				<div class="line"></div>
				<div class="text fontsize32">河狸讨论区</div>
			</div>
			<div class="dataListbox">
				<div class="itemobj" :class="getRowClass(index)" v-for="(item,index) in dataList" :key="index" @click="handledetail(item)">
					<div class="groupname clamp fontsize16">{{item.title}}</div>
					<div class="groupcon clamp fontsize20">{{item.description}}</div>
					<div class="grouptime fontsize14">{{$util.replaceTspli(item.createDate)}}</div>
				</div>
			</div>
			<div class="dataListbox" v-if="dataList.length<=0">
				<div class="nolist">你暂未加入学习讨论区，请联系教师邀请加入！</div>
			</div>
		</div>
		
		<div class="block30"></div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
export default {
	data() {
		return {
			typeindex:0,//0是小 1是详情
			dataList:[],//团
			typeList:[],//类型
			dataobj:{},//对象
			infoTags:[],//类型
		};
	},
	mounted: function() {
		//获取我的社团
		this.getmyGroup()
	},
	computed: {
		...mapState(['userInfo', 'isLogin','sysRole']),
	},
	methods: {
		//获取我的
		getmyGroup(){
			var _this = this
			//需要登录
			if(this.isLogin){
				var params = {
					currentPage:1,
					pageSize:8888,
				}
				params["userUuid"] = this.userInfo.uuid
				this.$http.post('app/content/ctDiscussGroup/index', params).then(function(res) {
					//数据处理
					if (res.code == 200) {
						_this.dataList = res.data.records
					} else {
						_this.$confirm(res.message, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {}).catch(() => {});
					}
				})
			}
		},
		//颜色
		getRowClass(index) {
		    // 根据需要判断第1第2第3第4列的倍数
		    const multipleClasses = {
		      '1': 'first-column',
		      '2': 'second-column',
		      '3': 'third-column',
		      '4': 'fourth-column'
		    };
		    let classNames = '';
		    Object.keys(multipleClasses).forEach(multiple => {
				if (index % multiple === 0) {
					classNames += ` ${multipleClasses[multiple]}`;
				}
		    });
		    return classNames.trim();
		},
		//标签
		tagFormat(tag){
			const obj = this.typeList.find((ifitem) => { //这里的options就是上面遍历的数据源
				return ifitem.id == tag; //筛选出匹配数据
			});
			if(obj){
				return obj.title
			}else{
				return tag
			}
		},
		//跳转
		handledetail(item){
			localStorage.setItem("discussGroup",JSON.stringify(item))
			this.$router.push({
				path: "/discussPage",
				query:{
					uid:item.id
				}
			});
		},
		
		
	}
};
</script>
<style lang="scss" scoped></style>
